<template>
  <div>
    <PageHeaderLayout>
      <div>
        <ApeMenu :menus='menus' />
        <!--        <div>-->
        <!--          流程：-->
        <!--          <ul>-->
        <!--            <li>绩效目录一览：MCN中心的红人季度目标设置</li>-->
        <!--            <li>项目组绩效：红人所在项目组-设置红人考核项及其目标，月绩效考核</li>-->
        <!--            <li>总监绩效：总监负责的MCN中心下部门总监的考核，季度绩效考核</li>-->
        <!--          </ul>-->

        <!--        </div>-->
      </div>
      <div class='main-page-content' v-show='this.$route.matched.length===2'>
        <div style='text-transform: capitalize;'>
          <h2>绩效管理和评价（performance appraisal ）</h2>
        </div>
        <div class='box'>
          <h2>注意事项</h2>
<!--          <div class='module-box shadow-effect'>-->
<!--            <div>-->
<!--              <span class='title'>绩效目标一览</span>-->
<!--              <p>-->
<!--                1、初始化：当前所选季度的 “红人绩效目标（继承上季度的考核设置）”、“项目组绩效（红人当前归属项目组）”，“总监绩效（默认MCN中心为"MCN第一中心、MCN第二中心"下的部门）”<br />-->
<!--                1、修改《涨粉目标》自动同步至 “项目组绩效”的涨粉目标（数据状态为“编辑中”，即需要发布后才生效）<br />-->
<!--                2、修改《营收目标》自动同步至 “项目组绩效”、 “总监绩效”的营收目标（数据状态为“编辑中”，，即需要发布后才生效）<br />-->
<!--              </p>-->

<!--            </div>-->
<!--          </div>-->
          <div class='module-box shadow-effect'>
            <span class='title'>项目组绩效（月考核）</span>
            <p>
              1、初始化：《绩效目标一览》初始化的时候同步初始化红人当前所在《项目组绩效》数据，考核项和绩效目标值同《绩效目标一览》（数据状态为“编辑中”，即需要发布后才生效）<br />
              2、绩效目标：变更情况分三种，分别直接修改项目组绩效、批量修改项目绩效、对应红人的绩效目标调整。这些操作的数据仅保存到“编辑中”的数据（PS：后操作的会覆盖先操作的，但是不影响已发布的数据）<br />
              3、团队奖金：启动编辑模式，修改项目组的团队奖金，该操作的数据仅保存到“编辑中”的数据（PS：不影响已发布的团队奖金）<br />
              4、确认发布：“编辑中”的绩效目标和团队奖金，需要通过【确认发布】操作，才生效。（PS：已确认发布的团队奖金的项目组才能被初始化到奖金分配列表）<br />
            </p>
          </div>
          <div class='module-box shadow-effect'>
            <span class='title'>总监绩效(季度考核)</span>
            <p>
              1、初始化：默认MCN中心为"MCN第一中心、MCN第二中心"，初始化MCN中心及其下部门，若需要设置其他部门为MCN中心，可通过【初始化】按钮手动选择部门后，确认初始化（根据实际情况设置即可）<br />
              2、营收绩效目标：若MCN下的部门下的组内有红人，则被设置为初始红人，其考核项 同《绩效目标一览》考核设置；若组内无红人或需求追加其他组的红人，可通过【红人管理】按钮手动添加红人<br />
              3、部门总监：作为季度考核对象，部门总监必须设置，若部门未设置”主管“（钉钉组织架构设置）或需要修改考核的总监，可通过【红人管理】或者【考核部门】修改总监<br />
            </p>
          </div>
        </div>
      </div>
    </PageHeaderLayout>
  </div>
</template>


<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import myNav from '@/json/menus.json'

export default {
  name: 'PaIndex',
  components: { PageHeaderLayout },
  data() {
    return {
      menus: [
        // {
        //   "icon": "mubiao",
        //   "display_name": "绩效目标一览",
        //   "url": "/pa/kol",
        //   "permission_name": "performance_trend_page"
        // },
        // {
        //   "icon": "mubiao",
        //   "display_name": "项目组绩效",
        //   "url": "/pa/dept",
        //   "permission_name": "performance_trend_page"
        // },
        // {
        //   "icon": "discovery-index",
        //   "display_name": "总监绩效",
        //   "url": "/pa/chief",
        //   "permission_name": "perf_dept_list"
        // },
        // {
        //   "icon": "fenpei",
        //   "display_name": "奖金分配",
        //   "url": "/pa/bonus",
        //   "permission_name": "bonus_list"
        // }
      ]
    }
  },
  methods: {
    getMenus() {
      //统一菜单配置读取，避免调整时不同步
      let pageMenus = myNav.find(value => value.url === '/pa')

      return pageMenus || []
    }
  },
  created() {
    let menuObj = this.getMenus()
    this.menus = menuObj.children || []
    // console.log(this.menus)
  }
}
</script>

<style scoped>
.box {
  height: 80vh;
  width: 90%;
  background: #ffffff;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
  margin: auto;
  border-radius: 5px;

}

.module-box {
  /*height: 120px;*/
  width: 100%;
  border: #e97ca1 1px dashed;
  line-height: 60px;
  /*text-align: center;*/
  /*vertical-align: middle;*/
  margin: 5px;
  padding: 10px 20px;
  border-radius: 5px;
}

.module-box p {
  line-height: 1.5; /* 设置行高为字体大小的1.5倍 */
}

.module-box .title {
  font-size: 1.2em;
  font-weight: bold;
  line-height: 20px !important; /* 设置行高为字体大小的1.5倍 */
}
</style>
